import React from 'react';

import { Grid, CircularProgress } from '@material-ui/core';

const FullPageLoader = () => {
  return (
    <Grid container justify="center" alignContent="center" alignItems="center" className="h-screen">
      <Grid item xs={2} className="text-center">
        <CircularProgress className="text-it-green" />
      </Grid>
    </Grid>
  );
}

export default FullPageLoader;