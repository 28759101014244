import React, { Component, Suspense, lazy, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import FullPageLoader from './components/UI/FullPageLoader';
import { MPARTICLE_MODE } from "./config/constants"
import './App.css';

const MemberRegistration = lazy(() => import('./views/memberRegistration'));
const Registration = lazy(() => import('./views/registration'));
const InvalidURL = lazy(() => import('./components/UI/InvalidURL'));
const Admin = lazy(() => import('./views/admin'));

const isDevelopment = process.env.REACT_APP_ENV === "DEVELOPMENT";

class App extends Component {

  mParticleStart = (apiKey) => {
    /*eslint no-unused-expressions: [0, { "allowShortCircuit": true, "allowTernary": true, "allowTaggedTemplates": true }]*/
    window.mParticle = window.mParticle || {};
    window.mParticle.eCommerce = { Cart: {} };
    window.mParticle.EventType = { Unknown: 0, Navigation: 1, Location: 2, Search: 3, Transaction: 4, UserContent: 5, UserPreference: 6, Social: 7, Other: 8 }
    window.mParticle.Identity = {};

    window.mParticle.config = {
      isDevelopmentMode: MPARTICLE_MODE
    }
    console.log("mParticle-dev: " + window.mParticle.config.isDevelopmentMode);

    window.mParticle.config.rq = [];
    window.mParticle.ready = function (f) {
      window.mParticle.config.rq.push(f);
    }
    function a(o, t) { return function () { t && (o = t + "." + o); var e = Array.prototype.slice.call(arguments); e.unshift(o), window.mParticle.config.rq.push(e) } } var x = ["endSession", "logError", "logEvent", "logForm", "logLink", "logPageView", "setSessionAttribute", "setAppName", "setAppVersion", "setOptOut", "setPosition", "startNewSession", "startTrackingLocation", "stopTrackingLocation"], y = ["setCurrencyCode", "logCheckout"], z = ["login", "logout", "modify"]; x.forEach(function (o) { window.mParticle[o] = a(o) }), y.forEach(function (o) { window.mParticle.eCommerce[o] = a(o, "eCommerce") }), z.forEach(function (o) { window.mParticle.Identity[o] = a(o, "Identity") });

    var mp = document.createElement('script');
    mp.type = 'text/javascript';
    mp.async = true;
    mp.src = ('https:' === document.location.protocol ? 'https://jssdkcdns' : 'http://jssdkcdn') + '.mparticle.com/js/v2/' + apiKey + '/mparticle.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(mp, s);
  }

  render() {
    return (
      <BrowserRouter>
        <CssBaseline />
        <Suspense fallback={<FullPageLoader />}>
          <Switch>
            {
              (isDevelopment || (window.location.hostname.endsWith("insighttimer.com") && !window.location.hostname.startsWith("teams"))) &&
              <Fragment>
                <Route exact path="/member-registration"
                  render={(routeProps) => {
                    routeProps.match.params.teamId = window.location.hostname.split(".")[0];
                    return <MemberRegistration {...routeProps} />
                  }} />
                <Route path="/admin"
                  render={(routeProps) => {
                    routeProps.match.params.teamId = window.location.hostname.split(".")[0];
                    return <Admin {...routeProps} />
                  }} />
                <Route exact path="/"
                  render={(routeProps) => {
                    const teamId = window.location.hostname.split(".")[0];

                    if(teamId === "pop") {
                      const webLiveInviteLink = "https://web.insighttimer.com/workplace/53e57e189efc4c56bad1ac4481ef573a/invite";

                      window.location = webLiveInviteLink;
                      return null;
                    }

                    routeProps.match.params.teamId = teamId;
                    return <Registration {...routeProps} />
                  }} />
              </Fragment>
            }
            <Route exact path="/:teamId/member-registration" component={MemberRegistration} />
            <Route path="/:teamId/admin" component={Admin} />
            <Route exact path="/:teamId" component={Registration} />
            <Route component={InvalidURL} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    )
  }

  componentWillMount() {
    this.mParticleStart(process.env.REACT_APP_MPARTICLE)
  }

}

export default App;
