
export const FUNCTIONS_HOST = process.env.REACT_APP_FUNCTIONS_HOST

export const MPARTICLE_MODE = process.env.REACT_APP_MPARTICLE_MODE === 'production' ? false : true

export const ERROR_MESSAGES = {
  sign_in: {
    email_wrong_format: "Email is invalid.",
    email_required: "Email is required.",
    password_required: "Password is required."
  },
  auth_form: {
    name_required: "Full Name is required.",
    email_wrong_format: "Email is invalid.",
    email_required: "Email is required.",
    password_required: "Password is required.",
    password_min_required: "Password minimum 6 chars.",
    password_alpha_required: "Only alphanumeric and . , + = ! ? ( ) [ ] characters allowed.",
  },
  invite_popup: {
    email_wrong_format: "Email is invalid.",
    email_required: "Email is required.",
  }
}
